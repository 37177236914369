import get from 'lodash.get';
import { FileImageSharp } from '@content/types/api';
import { VsTerminalsComparisonProps } from '@content/types/vsTerminals';

export const vsTerminalsTransformers = page => {
  const vsTerminalsLanguages = {};

  page.allSiteSettingEntityKasaFiskalnaVsKasoterminal.edges.forEach(edge => {
    const data = edge.node;
    const comparisonItems: VsTerminalsComparisonProps[] = [];

    data.relationships.field_components.forEach(component => {
      switch (component.__typename) {
        case 'paragraph__2_zdjecia_opisy_link':
          comparisonItems.push(component);
          break;
      }
    });

    vsTerminalsLanguages[data.langcode] = {
      seo: {
        title: get(data, 'field_meta_title', ''),
        description: get(data, 'field_meta_description', ''),
        image: get(data, 'relationships.field_main_image.localFile.publicURL'),
      },
      hero: {
        title: get(data, 'field_hero_headline.value', ''),
        paragraph: get(data, 'field_hero_text.value', ''),
        button: get(data, 'field_hero_button_text', ''),
        image: get(data, 'relationships.field_hero_image.localFile', null) as FileImageSharp,
        imageMobile: get(
          data,
          'relationships.field_hero_image_mobile.localFile',
          null
        ) as FileImageSharp,
        imageAlt: get(data, 'field_hero_image.alt', ''),
        video: get(data, 'field_hero_video', ''),
        videoThumbnail: get(
          data,
          'relationships.field_hero_video_thumbnail.localFile',
          null
        ) as FileImageSharp,
        videoThumbnailAlt: get(data, 'field_hero_video_thumbnail.alt', ''),
      },
      comparison: comparisonItems.map(item => ({
        image1: get(
          item,
          'relationships.field_image.localFile.childImageSharp',
          null
        ) as FileImageSharp,
        image2: get(
          item,
          'relationships.field_image_1.localFile.childImageSharp',
          null
        ) as FileImageSharp,
        title: get(item, 'field_short_text_1.value', ''),
        contentPlus: get(item, 'field_text_1.value', ''),
        contentMinus: get(item, 'field_text_2.value', ''),
        label1: get(item, 'field_label_1', ''),
        label2: get(item, 'field_label_2', ''),
        image1Alt: get(item, 'field_image.alt', ''),
        image2Alt: get(item, 'field_image_1.alt', ''),
        button: {
          title: get(item, 'field_nc_button_text', ''),
          href: get(item, 'field_btn_url', ''),
          onlyText: get(item, 'field_only_text', false),
        },
      })),
      costsChart: {
        title: get(data, 'field_text_1.value', ''),
        list: get(data, 'field_opisy_wykresow', []),
      },
      banner: {
        paragraph: get(data, 'field_text_2.value', ''),
        button1: get(data, 'field_text_3.value', ''),
        button2: get(data, 'field_text_4.value', ''),
      },
    };
  });

  return vsTerminalsLanguages;
};
