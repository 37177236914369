import { graphql, useStaticQuery } from 'gatsby';
import { VsTerminalsDataProps } from '@content/types/vsTerminals';
import * as vsTerminalsTransformers from '@content/transformers/VsTerminalsTransformers';

const VsTerminalsQuery = (): VsTerminalsDataProps => {
  const vsTerminalsQuery = useStaticQuery(graphql`
    query {
      allSiteSettingEntityKasaFiskalnaVsKasoterminal {
        edges {
          node {
            langcode
            field_hero_headline {
              value
            }
            field_hero_text {
              value
            }
            field_meta_description
            field_meta_title
            field_hero_image {
              alt
            }
            field_hero_image_mobile {
              alt
            }
            field_hero_video_thumbnail {
              alt
            }
            field_hero_button_text
            field_hero_video
            field_opisy_wykresow
            field_settings_tresc {
              value
            }
            field_text_1 {
              value
            }
            field_text_2 {
              value
            }
            field_text_3 {
              value
            }
            field_text_4 {
              value
            }
            relationships {
              field_main_image {
                localFile {
                  publicURL
                }
              }
              field_hero_image {
                localFile {
                  childImageSharp {
                    fixed(width: 1600, quality: 90) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              field_hero_image_mobile {
                localFile {
                  childImageSharp {
                    fixed(width: 800, quality: 90) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              field_hero_video_thumbnail {
                localFile {
                  childImageSharp {
                    fixed(width: 900, quality: 90) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              field_components {
                ... on paragraph__2_zdjecia_opisy_link {
                  field_label_2
                  field_image {
                    alt
                  }
                  field_image_1 {
                    alt
                  }
                  field_label_1
                  field_nc_button_text
                  field_text_1 {
                    value
                  }
                  field_text_2 {
                    value
                  }
                  field_btn_url
                  field_only_text
                  field_short_text_1 {
                    value
                  }
                  relationships {
                    field_image {
                      localFile {
                        childImageSharp {
                          fixed(width: 630, quality: 100) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                    field_image_1 {
                      localFile {
                        childImageSharp {
                          fixed(width: 350, quality: 100) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      vsTerminalsIntroImage1: file(relativePath: { eq: "vs-yellow@2x.jpg" }) {
        childImageSharp {
          fixed(width: 650, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      vsTerminalsIntroImage2: file(relativePath: { eq: "vs-blue@2x.jpg" }) {
        childImageSharp {
          fixed(width: 630, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return vsTerminalsTransformers.vsTerminalsTransformers(vsTerminalsQuery);
};

export default VsTerminalsQuery;
