import React, { Component } from 'react';
import styled from '@emotion/styled';
import { respondFrom, breakpoints, css } from '@styles';

import Page from '@components/layout/Page';
import Container from '@components/layout/Container';
import { VsTerminalsDataProps } from '@content/types/vsTerminals';
import { VsTerminalsComparison } from '@components/kasaVsKasoterminal/VsTerminalsComparison';
import { VsTerminalsCosts } from '@components/kasaVsKasoterminal/VsTerminalsCosts';
import { VsTerminalsBanner } from '@components/kasaVsKasoterminal/VsTerminalsBanner';
import PageHero from '@components/common/PageHero';
import AnimationVsHero from '@animations/AnimationVsHero';

const VsTerminalsContentWrapper = styled.div`
  padding-bottom: 30px;

  ${respondFrom(
    breakpoints.md,
    css`
      padding-bottom: 120px;
    `
  )}
`;

const StyledAnimationVsHero = styled(AnimationVsHero)`
  display: none;

  ${respondFrom(
    breakpoints.md,
    css`
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(80%, 44%);
    `
  )}
`;

export interface VsTerminalsProps extends VsTerminalsDataProps {
  langcode: string;
  urls: Array<string>;
}

class VsTerminals extends Component<VsTerminalsProps> {
  state = {
    scoreSum: 0,
  };

  onScoreChange = (scoreSum: number) => {
    this.setState({ scoreSum });
  };

  render() {
    const { hero, comparison, costsChart, banner, langcode, urls } = this.props;
    return (
      <Page>
        <PageHero
          animation={<StyledAnimationVsHero sectionVisible={true} />}
          settings={hero}
          langcode={langcode}
          urls={urls}
        />

        <Container>
          <VsTerminalsContentWrapper>
            <VsTerminalsComparison
              comparison={comparison}
              onScoreChange={this.onScoreChange}
              scoreSum={this.state.scoreSum}
              scoreSettings={costsChart.list}
              langcode={langcode}
              urls={urls}
            />
            <VsTerminalsCosts settings={costsChart} />
            <VsTerminalsBanner
              onScoreChange={this.onScoreChange}
              settings={banner}
              langcode={langcode}
              urls={urls}
            />
          </VsTerminalsContentWrapper>
        </Container>
      </Page>
    );
  }
}

export default VsTerminals;
